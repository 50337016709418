import React, { useEffect, useLayoutEffect, useRef } from 'react';


function Canvas(props) {
  const canvasRef = useRef(null)
  const { draw, handleClick, ...rest } = props

  function resizeCanvas(canvas) {
    const { width, height } = canvas.getBoundingClientRect()

    if (canvas.width !== width || canvas.height !== height) {
      canvas.width = width
      canvas.height = height
      return true // here you can return some usefull information like delta width and delta height instead of just true
      // this information can be used in the next redraw...
    }

    return false
  }

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    //Our first draw
    resizeCanvas(canvas)
    draw(context)
  }, [draw])

  return (
    <canvas ref={canvasRef} onClick={(event) => props.handleClick(event, canvasRef)} {...rest} />
  );
}

export default Canvas