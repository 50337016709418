import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { LinkContainer } from 'react-router-bootstrap'
import Button from 'react-bootstrap/Button';
import { Trans, useTranslation } from 'react-i18next';

const Home = () => {
  const [t, _] = useTranslation();

  return (
    <Container className="opacity-container">
      <Row className="justify-content-md-center"><Col md="auto" className="my-3"><h2>{t('home-home')}</h2> </Col></Row>
      <Row className="justify-content-md-center">
        <Col md="auto" >
          <Card>
            <Card.Body>
              <Card.Text className="lead">
                {t('home-welcomeText')}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

      </Row>
      <Row className="justify-content-md-center">
        <Col md="auto" className="my-2">
          <Card className="clickable" style={{ width: '22rem' }}>
            <Card.Img variant="top" src="example-2d.jpg" alt="Example of a 2d voronoi diagram" />
            <Card.Body>
              <Card.Title>{t('home-card2DTitle')}</Card.Title>
              <Card.Text>
                {t('home-card2DBody')}
              </Card.Text>
              <LinkContainer to="/voronoi2d">
                <Button variant="primary"><Trans t={t}>home-card2DButton</Trans></Button>
              </LinkContainer>
            </Card.Body>
          </Card>
        </Col>
        <Col md="auto" className="my-2">
          <Card className="clickable" style={{ width: '22rem' }}>
            <Card.Img variant="top" src="example-3d.jpg" alt="Example of a 3d voronoi diagram applied to the sphere" />
            <Card.Body>
              <Card.Title>{t('home-card3DTitle')}</Card.Title>
              <Card.Text>
                {t('home-card3DBody')}
              </Card.Text>
              <LinkContainer to="/voronoi3d">
                <Button variant="primary"><Trans t={t}>home-card3DButton</Trans></Button>
              </LinkContainer>
            </Card.Body>
          </Card>

        </Col>
      </Row>
      <Row className="justify-content-md-center">

        <Col md="auto">
          <LinkContainer to="/concepts">
            <Button variant="dark">{t('home-startDefinitions')}</Button>
          </LinkContainer>
        </Col>
      </Row>

    </Container>
  )
};

export default Home;