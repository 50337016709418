import React, { useRef, useMemo, useCallback } from 'react'
import { Vector3 } from "three"

const Line3D = (props) => {
  const ref = useRef();

  const points = useMemo(() => [new Vector3(0, 0, 0), new Vector3().fromArray(props.point).setLength(5.05)], [props.point])

  const onUpdate = useCallback(self => self.setFromPoints(points), [points])

  return (
    <>
      <line ref={ref}>
        <bufferGeometry attach="geometry" onUpdate={onUpdate} />
        <lineBasicMaterial attach="material" color={props.color} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
    </>
  )
}

export default Line3D;