import { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";


const CameraController = () => {
    const { camera, gl } = useThree();
    useEffect(
        () => {
            const controls = new OrbitControls(camera, gl.domElement);

            controls.enablePan = true;
            controls.minDistance = 5;
            controls.maxDistance = 40;
            controls.enableDamping = true;
            controls.autoRotate = false;
            return () => {
                controls.dispose();
            };
        },
        [camera, gl]
    );
    return null;
};

export default CameraController;