import React, { useState,useMemo, useCallback, useRef} from 'react'
import { Spherical, Color, Vector3, Float32BufferAttribute } from "three";
import Line3D from './line3d';

const Sphere = (props) => {

    const [lastCoords, setLastCoords] = useState(null)
    const refPoints = useRef();

    const oBC = useCallback((shader) => {
        shader.vertexShader = `
          uniform sampler2D globeTexture;
        varying float vVisibility;
        varying vec3 vNormal;
        varying vec3 vMvPosition;
        ${shader.vertexShader}
      `.replace(
            `gl_PointSize = size;`,
            `
            vVisibility = texture(globeTexture, uv).g; // get value from texture
          gl_PointSize = size * (vVisibility < 0.5 ? 1. : 0.75); // size depends on the value
          vNormal = normalMatrix * normalize(position);
          vMvPosition = -mvPosition.xyz;
          gl_PointSize *= 0.4 + (dot(normalize(vMvPosition), vNormal) * 0.6); // size depends position in camera space
        `
        );
        shader.fragmentShader = `
          varying float vVisibility;
        varying vec3 vNormal;
        varying vec3 vMvPosition;
        ${shader.fragmentShader}
      `.replace(
            `vec4 diffuseColor = vec4( diffuse, opacity );`,
            `
            bool circ = length(gl_PointCoord - 0.5) > 0.5; // make points round
          bool vis = dot(vMvPosition, vNormal) < 0.; // visible only on the front side of the sphere
            if (circ || vis) discard;
          
          vec3 col = diffuse + (vVisibility > 0.5 ? 0.5 : 0.); // make oceans brighter
          
          vec4 diffuseColor = vec4( col, opacity );
        `
        );
    }, [])

    const bufferAttributes = useMemo(() => {
        // <GLOBE>
        // https://web.archive.org/web/20120107030109/http://cgafaq.info/wiki/Evenly_distributed_points_on_sphere#Spirals
        let rad = 5;
        let sph = new Spherical();

        let r = 0;
        let dlong = Math.PI * (3 - Math.sqrt(5));
        let dz = 2 / props.counter;
        let long = 0;
        let z = 1 - dz / 2;

        let pts = [];
        let clr = [];
        let c = new Color();
        let uvs = [];

        for (let i = 0; i < props.counter; i++) {
            r = Math.sqrt(1 - z * z);
            let p = new Vector3(
                Math.cos(long) * r,
                z,
                -Math.sin(long) * r
            ).multiplyScalar(rad);
            if (Math.sin(long) < 0) {
                pts.push(rad * Math.cos(long) * r, z * rad, -Math.sin(long) * r * rad);
            }
            z = z - dz;
            long = long + dlong;
            if (props.darkMode) {
                if (props.colorsMode === "multi") {
                    c.setHSL(Math.random(), 0.8,  Math.random() * 0.10 + 0.15);
                }else if (props.colorsMode === "single") {
                    c.setHSL(0.12, 0.8,  Math.random() * 0.05 + 0.22);
    
                } else if (props.colorsMode === "duo") {
                    (Math.random()<0.5) ? c.setHSL(0.1 , 0.8,  0.30) : c.setHSL(0.4 , 0.3,  0.3);
                    
                }
            }else {
                if (props.colorsMode === "multi") {
                    c.setHSL(Math.random(), 0.8,  Math.random() * 0.25 + 0.25);
                }else if (props.colorsMode === "single") {
                    c.setHSL(0.12, 0.8,  Math.random() * 0.05 + 0.40);
    
                } else if (props.colorsMode === "duo") {
                    (Math.random()<0.5) ? c.setHSL(0.1 , 0.8,  0.25) : c.setHSL(0.4 , 0.3,  0.6);
                    
                }
            }
            
            c.toArray(clr, i * 3);
            sph.setFromVector3(p);
            uvs.push((sph.theta + Math.PI) / (Math.PI * 2), 1.0 - sph.phi / Math.PI);
        }
        if (refPoints.current) {
            refPoints.current.geometry.attributes.color.needsUpdate = true;
        }
        

        return {
            "points": new Float32BufferAttribute(new Float32Array(pts), 3)
            , "colors": new Float32BufferAttribute(clr, 3),
            "uvs": new Float32BufferAttribute(uvs, 2)
        };
    }, [props.counter, props.colorsMode, props.darkMode]);
    return (
        <points ref={refPoints}>
            <bufferGeometry>
                <bufferAttribute attach={"attributes-position"} {...bufferAttributes.points} />
                <bufferAttribute attach={"attributes-color"} {...bufferAttributes.colors} />
                <bufferAttribute attach={"attributes-uv"} {...bufferAttributes.uvs} />
            </bufferGeometry>
            <pointsMaterial
                size={0.15}
                onBeforeCompile={oBC}
                vertexColors={true}
            />
            <mesh rotation={[-Math.PI / 2, 0, 0]}>
                <sphereBufferGeometry attach="geometry" args={[5, 10, 6, Math.PI / 2, Math.PI * 2, Math.PI / 2, Math.PI / 2]} />
                <meshBasicMaterial attach="material" wireframe={true} color="#ffefb5" />
            </mesh>
            <mesh rotation={[Math.PI, 0, 0]}>
                <circleBufferGeometry attach="geometry" args={[5, 20]} rotation={[-Math.PI / 2, Math.PI / 2, Math.PI * 2]} />
                <meshBasicMaterial attach="material" wireframe={true} color="#ffefb5" />
            </mesh>
            <Line3D point={[5, 0, 0]} color='red'></Line3D>
            <Line3D point={[0, 5, 0]} color='green'></Line3D>
            <Line3D point={[0, 0, 5]} color='blue'></Line3D>
            {props.markers.map(el => el)}
            {props.bords.map(el => el)}
            {props.bordsVertices.map(el => el)}
            {(props.withTriangulation) ? props.bordsTriangulation.map(el => el) : null}
            {(props.withLEC) ? props.LEC : null}
            <mesh onClick={(ev) => props.handleClick(lastCoords, ev)} onPointerDown={(ev) => setLastCoords(ev.intersections[0].point)} rotation={[-Math.PI / 2, 0, 0]}>
                <sphereBufferGeometry args={[5, 25, 30, Math.PI / 2, Math.PI * 2, Math.PI / 2, Math.PI / 2]} ></sphereBufferGeometry>
                <meshBasicMaterial attach="material" wireframe={true} transparent={true} opacity={0} />
            </mesh>
        </points>
    );
};

export default Sphere;