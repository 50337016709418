import { React, useLayoutEffect, useMemo, useRef } from 'react';
import { Vector3, Triangle } from 'three';

import { Line2 } from 'three/examples/jsm/lines/Line2';

import { extend } from '@react-three/fiber';

extend({ Line2 });


function Ellipse3D(props) {
    const buffRef = useRef()
    const lineRef = useRef()

    let center = useMemo(() => new Vector3(0, 0, 0), [])
    // const position = [props.position.x, props.position.y, props.position.z];
    // const quaternion = [props.orientation.x, props.orientation.y, props.orientation.z, props.orientation.w];
    let pts = useMemo(() => {
        let pts = [];
        if (props.pointsLEC) {
            let vecTemp = new Vector3();
            for (let i = 0; i <= props.pointsLEC[0].length; i++) {
                vecTemp.fromArray([props.pointsLEC[0][i], props.pointsLEC[1][i], props.pointsLEC[2][i]]).setLength(5.04)
                pts.push(vecTemp.clone());
            }

        } else {
            let start = new Vector3().fromArray(props.start).setLength(5.04)
            let end = new Vector3().fromArray(props.end).setLength(5.04)
            let vS = new Vector3().subVectors(start, center);
            let vE = new Vector3().subVectors(end, center);
            let a = vS.angleTo(vE); // andgle between start and end, relatively to the new center
            let divisions = 100;
            let aStep = a / divisions;
            let vecTemp = new Vector3();
            let tri = new Triangle(start, center, end);
            let axis = new Vector3();
            tri.getNormal(axis); // get the axis to rotate around
            for (let i = 0; i <= divisions; i++) {
                vecTemp.copy(vE);
                vecTemp.applyAxisAngle(axis, aStep * i);
                pts.push(vecTemp.clone());
            }
        }

        return pts
    }, [props.start, props.end, props.pointsLEC, center])

    useLayoutEffect(() => {
        if (buffRef.current) {
            buffRef.current.setFromPoints(pts);
        }
        if (lineRef.current) {
            lineRef.current.computeLineDistances();
            lineRef.current.position.copy(center);
        }
    }, [pts, center]);

    return (
        <line ref={lineRef} position={[0, 0, 0]}>
            <bufferGeometry ref={buffRef} lineWidth={1} />
            <lineBasicMaterial color={props.color} />
        </line>
    );
}

export default Ellipse3D;