import Table from 'react-bootstrap/Table';

function InfosTable(props) {
  let reversed_data = [...props.data].reverse()
  return (
    <Table className="opacity-container" striped hover size="sm">
      <thead>
        <tr>
          <th>date</th>
          <th>level</th>
          <th>event</th>
          <th>infoLabel</th>
          <th>value</th>
        </tr>
      </thead>
      <tbody>
        {Array.from(reversed_data).map((el, index) => (
          <tr key={el.id}>
            <td>{el.id}</td>
            <td>{el.level}</td>
            <td>{el.event}</td>
            <td>{el.infoLabel}</td>
            <td>{el.value}</td>
          </tr>
        ))}
        {/* <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <td>3</td>
          <td colSpan={2}>Larry the Bird</td>
          <td>@twitter</td>
        </tr> */}
      </tbody>
    </Table>
  );
}

export default InfosTable