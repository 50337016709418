import './voronoi3d.css';
import React, { useState, useEffect } from 'react'


import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import InfosTable from '../infosTable';
import Marker from './objects3d/marker';
import Canvas3d from './canvas3d';
import Ellipse3D from './objects3d/ellipse3d';
import Line3D from './objects3d/line3d';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Voronoi3d = (props) => {
  const [error, setError] = useState(null);
  const [arePointsLoaded, setArePointsLoaded] = useState(false);
  const [currentSave, setCurrentSave] = useState(null);
  const [pointList, setPointList] = useState([]);
  const [colorsMode, setColorsMode] = useState("single") // "multi", "duo" or "single"
  const [withTriangulation, setWithTriangulation] = useState(false)
  const [withLEC, setWithLEC] = useState(false)
  const [isInfos, setIsInfos] = useState(false)
  const [infos, setInfos] = useState([])
  const [markers, setMarkers] = useState([])
  const [bords, setBords] = useState([])
  const [bordsVertices, setBordsVertices] = useState([])
  const [bordsTriangulation, setBordsTriangulation] = useState([])
  const [LEC, setLEC] = useState(null)
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (performance.getEntriesByType("navigation")[0].type === 1) {
      resetCanvas();
    }
    window.addEventListener("beforeunload", resetCanvas) // pour reset en cas de rechargement de la table
    async function fetchPoints() {
      let bodyPayload
      if (pointList.length === 0) {
        bodyPayload = { "points": [{ "x": -1, "y": -1, "z": -1 }] }
      } else {
        bodyPayload = { "points": pointList }
      }
      if (currentSave != null) {
        bodyPayload["save"] = currentSave;
      } else {
        bodyPayload["save"] = ""
      }
      let time1 = performance.now();
      // for prod : https://website-voronoi-backend-v4wvu254qa-ew.a.run.app
      // for dev : https://website-voronoi-backend-v4wvu254qa-ew.a.run.app
      let response = await fetch("https://website-voronoi-backend-v4wvu254qa-ew.a.run.app/getVoronoiSphere?withTriangulation=" + withTriangulation.toString() + "&withLEC=" + withLEC,
        {
          method: 'post',
          credentials: 'include',
          headers: new Headers({
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Request-Method': 'POST',
            "Content-type": "application/json"
          }),
          body: JSON.stringify(bodyPayload)
        })
        .then(res => res.json())
        .then(
          (result) => {
            setArePointsLoaded(true);
            return (result);
          },
          (error) => {
            setArePointsLoaded(true);
            setError(error);
          }
        )
      if ("save" in response) {
        setCurrentSave(response.save);
      }
      let time2 = performance.now();
      setInfos(() => [...infos, { "id": new Date().toISOString(), "level": "info", "event": "Server response", "infoLabel": "Response time ms", "value": (time2 - time1).toFixed(2).toString() + " ms" }])
      let bordsTmp = []
      let bordsVerticesTmp = []
      let keysVertices = []
      let keysBords = []
      for (const bord of response.bords) {
        for (const p of bord) {
          let new_key = p.toString()
          if (!(keysVertices.includes(new_key))) {
            bordsVerticesTmp.push(<Line3D key={new_key} point={p} color='#b56464'></Line3D>)
            keysVertices.push(new_key)
          }
        }
        let p_prev = bord[0]
        for (const p_current of bord.slice(1)) {
          let new_key = p_prev.toString() + p_current.toString()
          if (!(keysBords.includes(new_key))) {
            bordsTmp.push(
              <Ellipse3D key={new_key} start={p_prev} end={p_current} color="#0000ff"></Ellipse3D>
            )
          }
          p_prev = p_current
        }
      }
      setBordsVertices(() => bordsVerticesTmp)
      setBords(() => bordsTmp)

      if (withTriangulation && pointList.length > 2) {
        let bordsTriangulationTmp = []
        for (const duoP of response.triangulation) {
          bordsTriangulationTmp.push(<Ellipse3D start={duoP[0]} end={duoP[1]} color="#00fbff"></Ellipse3D>)
        }
        setBordsTriangulation(() => bordsTriangulationTmp)
      }

      if (withLEC && pointList.length > 2) {
        setLEC(() => <Ellipse3D pointsLEC={response.lec} color="red"></Ellipse3D>)
      }
    }
    try {
      fetchPoints()
    } catch (e) {
      console.log(e);
    }

  }, [pointList, withTriangulation, withLEC])

  const addMarker = (lastCoords, ev) => {
    let limit = 0.2
    if (Math.abs(lastCoords.x - ev.intersections[0].point.x) < limit && Math.abs(lastCoords.y - ev.intersections[0].point.y) < limit && Math.abs(lastCoords.z - ev.intersections[0].point.z) < limit) {
      let nbMarkers = markers.length
      ev.intersections[0].point.normalize()
      setMarkers(() => [...markers, <Marker key={nbMarkers + 1} intersection={ev.intersections[0].point} />])
      let newPointList = pointList.slice()
      newPointList.push(ev.intersections[0].point)
      setPointList(newPointList)
      setInfos([...infos, { "id": new Date().toISOString(), "level": "info", "event": "New point added", "infoLabel": "Total nb points", "value": nbMarkers + 1 }])
    }
  }
  const resetCanvas = () => {
    setInfos([])
    setLEC(null)
    setPointList([])
    setMarkers([])
    setBords([])
    setBordsVertices([])
    setBordsTriangulation([])
  }

  const switchColorsMode = () => {
    if (colorsMode === "multi") {
      setColorsMode("duo")
    } else if (colorsMode === "duo") {
      setColorsMode("single")
    } else {
      setColorsMode("multi")
    }
  }

  if (error) {
    return <div className="my-3">Error: {error.message}</div>;
  } else if (!arePointsLoaded) {
    return (
      <div className="my-3">
        <header className="App-header">
          Voronoi Diagram 3D
        </header>
        <div>Loading...</div>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return (
      <div>
        <Row className="canvas-controls my-3">
          <Col>
            <Button className="me-2" variant="warning" onClick={resetCanvas}>{t('controls-reset')}</Button>
            <Button className="me-2" onClick={switchColorsMode}>{t('controls-colors')} <div className="vr"></div> {colorsMode}</Button>
            <Button className="me-2" onClick={() => { setWithTriangulation(!withTriangulation) }}>{t('controls-triang')} <div className="vr"></div> {withTriangulation ? <FontAwesomeIcon icon="check" size="lg" /> : <FontAwesomeIcon icon="xmark" size="lg" />}</Button>
            <Button className="me-2" onClick={() => { setWithLEC(!withLEC) }}>{t('controls-lec')} <div className="vr"></div> {withLEC ? <FontAwesomeIcon icon="check" size="lg" /> : <FontAwesomeIcon icon="xmark" size="lg" />}</Button>
            <Button className="me-2" variant="dark" onClick={() => { setIsInfos(!isInfos) }}>{t('controls-logs')} <div className="vr"></div> {isInfos ? <FontAwesomeIcon icon="check" size="lg" /> : <FontAwesomeIcon icon="xmark" size="lg" />}</Button>
          </Col>
        </Row>
        <Row className="canvas-row canvas-container">
          <Canvas3d handleClick={addMarker} darkMode={props.darkMode} colorBG={props.colorBG} markers={markers} counter={28000} colorsMode={colorsMode} bords={bords} bordsVertices={bordsVertices} bordsTriangulation={bordsTriangulation} withTriangulation={withTriangulation} LEC={LEC} withLEC={withLEC}></Canvas3d>
        </Row>
        <Row className="infos-container">
          {isInfos ? <InfosTable data={infos}></InfosTable> : null}
        </Row>
      </div>
    );
  }
}

export default Voronoi3d;
