import React, { useRef, useEffect, useCallback } from 'react'
import { useFrame } from '@react-three/fiber'
import { Object3D, InstancedBufferAttribute } from 'three';

let globalUniforms = {
  time: { value: 0 }
};

function Marker(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef()
  const pGeom = useRef()
  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    globalUniforms.time.value = time;
  })
  const oBC = useCallback((shader) => {
    shader.uniforms.time = globalUniforms.time;
    shader.vertexShader = `
          	attribute float phase;
            varying float vPhase;
            ${shader.vertexShader}
          `.replace(
      `#include <begin_vertex>`,
      `#include <begin_vertex>
            	vPhase = phase; // de-synch of ripples
            `
    );
    shader.fragmentShader = `
          	uniform float time;
            varying float vPhase;
          	${shader.fragmentShader}
          `.replace(
      `vec4 diffuseColor = vec4( diffuse, opacity );`,
      `
            vec2 lUv = (vUv - 0.5) * 4.;
            float val = 0.;
            float lenUv = length(lUv);
            val = max(val, 1. - step(0.2, lenUv)); // central circle
            // val = max(val, step(0.15, lenUv) - step(0.2, lenUv)); // outer circle
            
            float tShift = fract(time * 0.5 + vPhase);
            val = max(val, step(0.2 + (tShift * 0.6), lenUv) - step(0.3 + (tShift * 0.5), lenUv)); // ripple
            
            if (val < 0.5) discard;
            
            vec4 diffuseColor = vec4( diffuse, opacity );`
    );
  }, [])
  useEffect(() => {
    let rad = 5
    let dummy = new Object3D();
    dummy.position.copy(props.intersection).setLength(rad + 0.05);
    dummy.lookAt(dummy.position.clone().setLength(rad + 1));
    dummy.updateMatrix();
    mesh.position = dummy.position
    mesh.current.applyMatrix4(dummy.matrix)
    pGeom.current.setAttribute(
      "phase",
      new InstancedBufferAttribute(new Float32Array([Math.random()]), 1)
    );
  }, [props.intersection])

  return (
    <mesh
      key={props.markerKey}
      ref={mesh}
    >
      <planeGeometry ref={pGeom} />
      <meshBasicMaterial color={'#000dff'} defines={{ USE_UV: " " }} onBeforeCompile={oBC} />
    </mesh>
  )
}

export default Marker;
