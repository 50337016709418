import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Trans, useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image'
import explanations from './explanations.png'

const Concepts = () => {
  const [t, _]  = useTranslation();

  return (
    <Container className="opacity-container">
      <Row className="justify-content-md-center"><Col md="auto" className="my-3"><h2>{t('concepts-title')}</h2></Col></Row>

      <Row>
        <p className="lead">
          {t('concepts-subtitle')}
        </p>
      </Row>
      <Row>
        <p className="lead">
        {t('concepts-def')}
        </p>
      </Row>
      <Row>
        <dl className="row">
          <dt className="col-sm-3">{t('concepts-def-seedsKey')}</dt>
          <dd className="col-sm-9">{t('concepts-def-seeds')}</dd>
          <dt className="col-sm-3 text-truncate">{t('concepts-def-cellsKey')}</dt>
          <dd className="col-sm-9">{t('concepts-def-cells')}</dd>
          <dt className="col-sm-3">{t('concepts-def-triangKey')}</dt>
          <dd className="col-sm-9">{t('concepts-def-triang')}</dd>
          <dt className="col-sm-3">{t('concepts-def-lecKey')}</dt>
          <dd className="col-sm-9">{t('concepts-def-lec')}</dd>
        </dl>
      </Row>
      <Row>
        <p className="lead">
        {t('concepts-schema')}
        </p>
      </Row>
      <Row>
        <Col lg={5}><Trans t={t}>concepts-schemaContent</Trans></Col>
        
        <Col lg={7}><Image fluid src={explanations}></Image></Col>
      </Row>
      
    </Container>
  )
};

export default Concepts;