import React from 'react';
import variables from './App.scss';
import Voronoi2d from './components/voronoi2d/voronoi2d';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { LinkContainer } from 'react-router-bootstrap'
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Home from './components/home/home';
import About from './components/about/about';
import Concepts from './components/concepts/concepts';
import Voronoi3d from './components/voronoi3d/voronoi3d';
import Form from 'react-bootstrap/Form';
import ThemeContextWrapper from './contexts/themeWrapperContext';
import { ThemeContext, themes } from './contexts/themeContext';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { optionsDark, optionsLight } from './contexts/particleOptions';
import { useTranslation } from 'react-i18next';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMoon, faCheck, faXmark} from '@fortawesome/free-solid-svg-icons'
import { faSun } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faSun, faMoon, faCheck,faXmark)

const App = () => {
    const [darkMode, setDarkMode] = React.useState(true);
    const [language, setLanguage] = React.useState("fr");
    const { t, i18n } = useTranslation();
    const [particleOptions, setParticleOptions] = React.useState(optionsDark);

    const particlesInit = async (main) => {
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };

    // (ev) => {console.log(ev);i18n.changeLanguage(ev.target.value);}

    const particlesLoaded = (container) => {
        // console.log(container);
    };
    return (
        <ThemeContextWrapper>
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={particleOptions}
            />
            <BrowserRouter>
                <Navbar className="customNavBar" expand="lg">
                    <Container>
                        <Navbar.Brand href="#home">{t('topNav-title')}</Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link href="https://jlhervy.com">{t('topNav-back')}</Nav.Link>
                        </Nav>
                        <ToggleButtonGroup className="me-3" type="radio" name="languages" value={language} onChange={(ev) => { console.log(ev); setLanguage(ev); i18n.changeLanguage(ev); }}>
                            <ToggleButton id="tbg-btn-1" value={"fr"} variant="secondary">
                                Fr
                            </ToggleButton>
                            <ToggleButton id="tbg-btn-2" value={"en"} variant="secondary">
                                En
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ThemeContext.Consumer>
                            {({ changeTheme }) => (
                                <Form className="d-flex">
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        id="darkmodeswitch"
                                        onClick={() => {
                                            setDarkMode(!darkMode);
                                            changeTheme(!darkMode ? themes.dark : themes.light);
                                            setParticleOptions(!darkMode ? optionsDark : optionsLight)
                                        }}
                                    />
                                    <div>{darkMode ? <FontAwesomeIcon icon="moon" size="lg" /> : <FontAwesomeIcon icon={faSun} size="lg" />}</div>

                                </Form>
                            )}
                        </ThemeContext.Consumer>

                    </Container>
                </Navbar>
                <Container>
                    <Row>
                        <Col lg={2}>
                            <ListGroup variant="flush" className="mt-3">
                                <LinkContainer to="/">
                                    <ListGroup.Item action active={false}>{t('leftNav-home')}</ListGroup.Item>
                                </LinkContainer>
                                <LinkContainer to="/voronoi2d">
                                    <ListGroup.Item action active={false}> {t('leftNav-2d')}</ListGroup.Item>
                                </LinkContainer>
                                {/* <ListGroup.Item action><Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}>Home</Link></ListGroup.Item>
            <ListGroup.Item action><Link to="/voronoi2d" style={{ color: 'inherit', textDecoration: 'inherit'}}>Voronoi 2d app</Link></ListGroup.Item> */}
                                <LinkContainer to="/voronoi3d">
                                    <ListGroup.Item action active={false}>{t('leftNav-3d')} </ListGroup.Item>
                                </LinkContainer>
                                <LinkContainer to="/concepts">
                                    <ListGroup.Item action active={false}>{t('leftNav-concepts')}</ListGroup.Item>
                                </LinkContainer>
                                <LinkContainer to="/about">
                                    <ListGroup.Item action active={false}>{t('leftNav-about')}</ListGroup.Item>
                                </LinkContainer>
                            </ListGroup>
                        </Col>
                        <Col lg={8}>
                            <Routes>
                                <Route path="/" element={<Home />}>
                                    <Route index element={<Home />} />
                                    <Route path="home" element={<Home />} />
                                </Route>
                                <Route path="/voronoi2d" element={<Voronoi2d />} />
                                <Route path="/voronoi3d" element={<Voronoi3d darkMode={darkMode} colorBG={darkMode ? variables.canvas3dDarkBackground : variables.canvas3dLightBackground} />} />
                                <Route path="/about" element={<About language={language} darkMode={darkMode} />} />
                                <Route path="/concepts" element={<Concepts />} />
                            </Routes>
                            <br></br>
                        </Col>
                        <Col lg={2}></Col>
                    </Row>
                </Container>
            </BrowserRouter>
        </ThemeContextWrapper>
    )
}

export default App;