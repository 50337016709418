import ReactDOM from 'react-dom/client';
import App from './App';
import './App.scss';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <App></App>
);

