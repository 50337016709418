import './voronoi2d.css';
import Canvas from './canvas'
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import InfosTable from '../infosTable';

const Voronoi2d = () => {

  const [error, setError] = useState(null);
  const [currentSave, setCurrentSave] = useState(null);
  const [arePointsLoaded, setArePointsLoaded] = useState(false);
  const [pointList, setPointList] = useState([]);
  const [draw, setDraw] = useState(null)
  const [colorsMode, setColorsMode] = useState("single") // "multi", "duo" or "single"
  const [withTriangulation, setWithTriangulation] = useState(false)
  const [withLEC, setWithLEC] = useState(false)
  const [isInfos, setIsInfos] = useState(false)
  const [infos, setInfos] = useState([])
  const { t, i18n } = useTranslation();

  const handleClick = (event, canvas) => {
    const rect = canvas.current.getBoundingClientRect()
    const x = (event.clientX - rect.left) / rect.width
    const y = (event.clientY - rect.top) / rect.height
    let newPointList = pointList.slice()
    newPointList.push({ "x": x, "y": y })
    setPointList(newPointList)
    setInfos([...infos, { "id": new Date().toISOString(), "level": "info", "event": "New point added", "infoLabel": "Total nb points", "value": pointList.length }])
  }

  function mulberry32(a) {
    var t = a += 0x6D2B79F5;
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  }

  useEffect(() => {
    if (performance.getEntriesByType("navigation")[0].type === 1) {
      resetCanvas();
    }
    window.addEventListener("beforeunload", resetCanvas) // pour reset en cas de rechargement de la table
    async function fetchPoints() {
      let bodyPayload
      if (pointList.length === 0) {
        bodyPayload = { "points": [{ "x": -1, "y": -1 }] }
      } else {
        bodyPayload = { "points": pointList }
      }
      if (currentSave != null) {
        bodyPayload["save"] = currentSave;
      } else {
        bodyPayload["save"] = ""
      }
      let time1 = performance.now();
      // for prod : https://website-voronoi-backend-v4wvu254qa-ew.a.run.app
      // for dev : https://website-voronoi-backend-v4wvu254qa-ew.a.run.app
      let response = await fetch("https://website-voronoi-backend-v4wvu254qa-ew.a.run.app/getVoronoiPlan?withTriangulation=" + withTriangulation.toString() + "&withLEC=" + withLEC,
        {
          method: 'post',
          credentials: 'include',
          headers: new Headers({
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Request-Method': 'POST',
            "Content-type": "application/json"
          }),
          body: JSON.stringify(bodyPayload)
        })
        .then(res => res.json())
        .then(
          (result) => {
            setArePointsLoaded(true);
            return (result);
          },
          (error) => {
            setArePointsLoaded(true);
            setError(error);
          }
        )
      if ("save" in response) {
        setCurrentSave(response.save);
      }
      let time2 = performance.now();
      setInfos(() => [...infos, { "id": new Date().toISOString(), "level": "info", "event": "Server response", "infoLabel": "Response time ms", "value": (time2 - time1).toFixed(2).toString() + " ms" }])
      const draw = () => ctx => {
        ctx.fillStyle = "#aaaaaa"
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        let w = ctx.canvas.clientWidth;
        let h = ctx.canvas.clientHeight;
        ctx.lineWidth = 2
        for (const [i, bord] of response.bords.entries()) {
          let p_prev = bord[0]
          ctx.beginPath()
          ctx.lineWidth = 0.5
          ctx.moveTo(w * p_prev[0], h * p_prev[1]);
          for (const p of bord.slice(1)) {
            let x = Math.round(w * p[0])
            let y = Math.round(h * p[1])
            ctx.lineTo(x, y);
            ctx.stroke()
          }
          ctx.closePath()
          if (pointList.length > i && colorsMode === "multi") {
            ctx.fillStyle = "#" + mulberry32(pointList[i].x * 10 ** 16).toString(16).slice(-6)
          } else if (colorsMode === "duo") {
            if (i % 2 === 0) {
              ctx.fillStyle = "#aaaaaa"
            } else {
              ctx.fillStyle = "#cccccc"
            }
          } else if (pointList.length > i && colorsMode === "single") {
            let firstPointRandomColor = mulberry32(pointList[0].x * 10 ** 16).toString().slice(-3) % 360;
            let firstPointRandomLum = mulberry32(pointList[0].y * 10 ** 16).toString().slice(-3) % 360;
            let firstPointSaturation = 20 + 80 * firstPointRandomColor / 360
            let firstPointLuminosity = 20 + 80 * firstPointRandomLum / 360
            let customVariation = mulberry32(pointList[i].x * 10 ** 16).toString().slice(-3) % 200;
            let color = (firstPointRandomColor + 360 * (customVariation / 1000)) % 360;
            ctx.fillStyle = 'hsl(' + color + ',' + firstPointSaturation + '%,' + firstPointLuminosity + '%)';
          }
          ctx.fill()
        }
        ctx.fillStyle = "#000000"
        ctx.font = ctx.font.replace(/\d+px/, "20px");
        if (pointList.length === 0) {
          ctx.fillText("Click me", w / 2 - 50, h / 2 - 10);
          ctx.fillStyle = '#000000'
        }
        for (const point of pointList) {
          let xP = Math.round(w * point.x)
          let yP = Math.round(h * point.y)
          ctx.fillRect(xP - 2, yP - 2, 4, 4);
        }

        if (withTriangulation && pointList.length > 1) {
          ctx.strokeStyle = '#00fbff'
          ctx.lineWidth = 1
          ctx.beginPath()

          for (const pairOfPoints of response.triangulation) {
            // ctx.beginPath()
            let xD = Math.round(w * pairOfPoints[0][0])
            let yD = Math.round(h * pairOfPoints[1][0])
            let xA = Math.round(w * pairOfPoints[0][1])
            let yA = Math.round(h * pairOfPoints[1][1])
            ctx.moveTo(xD, yD);
            ctx.lineTo(xA, yA);

          }
          ctx.stroke();
          ctx.closePath();
        }

        if (withLEC && pointList.length > 0) {
          // ctx.beginPath()
          let centre = response.lec[0]
          let rayon = response.lec[1]
          ctx.beginPath();
          ctx.strokeStyle = '#eb3449'
          ctx.lineWidth = 1
          let xC = Math.round(w * centre[0])
          let yC = Math.round(h * centre[1])
          ctx.ellipse(xC, yC, Math.round(rayon * w), Math.round(rayon * h), 0, 0, 2 * Math.PI);
          ctx.stroke();
          // for (const pairOfPoints of response.lec) {
          //   // ctx.beginPath()
          //   let xD = Math.round(w * pairOfPoints[0][0])
          //   let yD = Math.round(h * pairOfPoints[1][0])
          //   let xA = Math.round(w * pairOfPoints[0][1])
          //   let yA = Math.round(h * pairOfPoints[1][1])
          //   ctx.moveTo(xD, yD);
          //   ctx.lineTo(xA, yA);
          // }
          // ctx.stroke();
          ctx.closePath()
        }
        ctx.lineWidth = 0.2
        ctx.strokeStyle = '#000000'
      }
      setDraw(draw)
    }

    fetchPoints()

  }, [pointList, colorsMode, withTriangulation, withLEC])
  useEffect(() => {
    resetCanvas();
  }, [])
  const resetCanvas = () => {
    setInfos([])
    setPointList([])
  }

  const switchColorsMode = () => {
    if (colorsMode === "multi") {
      setColorsMode("duo")
    } else if (colorsMode === "duo") {
      setColorsMode("single")
    } else {
      setColorsMode("multi")
    }
  }


  if (error) {
    return <div className="my-3">Error: {error.message}</div>;
  } else if (!arePointsLoaded) {
    return (
      <div className="my-3">
        <header className="App-header">
          Voronoi Diagram 2D
        </header>
        <div>Loading...</div>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return (
      <div>
        <Row className="canvas-controls my-3">
          <Col>
            <Button className="me-2" variant="warning" onClick={resetCanvas}>{t('controls-reset')}</Button>
            <Button className="me-2" onClick={switchColorsMode}>{t('controls-colors')} <div className="vr"></div> {colorsMode}</Button>
            <Button className="me-2" onClick={() => { setWithTriangulation(!withTriangulation) }}>{t('controls-triang')} <div className="vr"></div> {withTriangulation ? <FontAwesomeIcon icon="check" size="lg" /> : <FontAwesomeIcon icon="xmark" size="lg" />}</Button>
            <Button className="me-2" onClick={() => { setWithLEC(!withLEC) }}>{t('controls-lec')} <div className="vr"></div> {withLEC ? <FontAwesomeIcon icon="check" size="lg" /> : <FontAwesomeIcon icon="xmark" size="lg" />}</Button>
            <Button className="me-2" variant="dark" onClick={() => { setIsInfos(!isInfos) }}>{t('controls-logs')}  <div className="vr"></div> {isInfos ? <FontAwesomeIcon icon="check" size="lg" /> : <FontAwesomeIcon icon="xmark" size="lg" />} </Button>
          </Col>
        </Row>
        <Row className="canvas-container">
          <Canvas draw={draw} handleClick={handleClick}></Canvas>
        </Row>
        <Row className="infos-container">
          {isInfos ? <InfosTable data={infos}></InfosTable> : null}
        </Row>
      </div>
    );
  }
}

export default Voronoi2d;
