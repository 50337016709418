import { ReactComponent as YourSvgLight } from './about.svg';
import { ReactComponent as YourSvgDark } from './about-dark.svg';
import { ReactComponent as YourSvgLightFR } from './aboutFR.svg';
import { ReactComponent as YourSvgDarkFR } from './about-darkFR.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Trans, useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';

const About = (props) => {
  const [t, _] = useTranslation();

  return (
    <Container className="mt-3 opacity-container">
      <div className="navbar-nav">
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title={t('about-technicalTab')}>
            <Row className="justify-content-md-center"><Col md="auto" className="my-3"><h2>{t('about-technicalTitle')}</h2></Col></Row>
            <Row>
              {props.language === "en" && props.darkMode ? <YourSvgDark className="my-3" /> : props.language === "en" && !props.darkMode ? <YourSvgLight className="my-3" /> : props.language === "fr" && props.darkMode ? <YourSvgDarkFR className="my-3" /> : <YourSvgLightFR className="my-3" />}

            </Row>
            <Row>
            <Col lg={2}/><Col><div>
                <Badge bg="warning">{t('about-technicalBadge')}</Badge> {t('about-technicalBadgeValue')}
              </div></Col>
              <Col lg={2}/>
            </Row>
          </Tab>
          <Tab eventKey="faq" title={t('about-faqTab')}>
            <Row className="justify-content-md-center"><Col md="auto" className="my-3"><h2>{t('about-faqTitle')}</h2></Col></Row>

            <Row>
              <p className="lead">
                &bull; {t('about-faqAlgorithmQ')}
              </p>
              <p>
                {t('about-faqAlgorithmA')}
              </p>
            </Row>
            <Row>
              <p className="lead">
                &bull; {t('about-faqPerpendicularQ')}
              </p>
              <p>
                {t('about-faqPerpendicularA')}
              </p>
            </Row>
            <Row>
              <p className="lead">
                &bull; {t('about-faqCirclebigQ')}
              </p>
              <p>
                {t('about-faqCirclebigA')}
              </p>
            </Row>
            <Row>
              <p className="lead">
                &bull; {t('about-faqBackendQ')}
              </p>
              <p>
                {t('about-faqBackendA')}
              </p>
            </Row>

          </Tab>
          <Tab eventKey="thanks" title={t('about-thankTab')}>
            <Trans t={t}>about-thankContent</Trans>
          </Tab>
        </Tabs>
      </div>
    </Container>
  )
};


export default About;