import React, { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber'
import { NoToneMapping } from "three";
import CameraController from './objects3d/cameraController';
import Sphere from './objects3d/sphere';

function Canvas3d(props) {
  const canvasRef = useRef(null)

  function resizeCanvas(canvas) {
    const { width, height } = canvas.getBoundingClientRect()
    
    if (canvas.width !== width || canvas.height !== height) {
      canvas.width = width
      canvas.height = height
      return true // here you can return some usefull information like delta width and delta height instead of just true
      // this information can be used in the next redraw...
    }

    return false
  }

  useEffect(() => {
    const canvas = canvasRef.current
    resizeCanvas(canvas)
    //Our first draw
  }, [])


  return (
    <Canvas className="canvas3d-container" ref={canvasRef}  gl={{ antialias: true, toneMapping: NoToneMapping }}
      linear camera={{ position: [0, -6, 7], near: 1, far: 2000 }}>
      <color attach="background" args={[props.colorBG]} />
      <CameraController />
      <Sphere {...props} />

    </Canvas>
  );
}

export default Canvas3d