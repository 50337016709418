import variables from '../App.scss';

export const optionsLight = {
    fullScreen: {
        enable: true,
        zIndex: -1
    },
    background: {
        color: {
            value: variables.particlesLightBackground,
        },
    },
    fpsLimit: 60,
    interactivity: {
        events: {
          onHover: {
            enable: true,
            mode: "bubble",
          },
          resize: true,
        },
        modes: {
          bubble: {
            distance: 150,
            size: 4,
            opacity : 1,
            duration: 1,
          },
        },
      },
    particles: {
        color: {
            value: variables.particlesLightColor,
        },
        links: {
            color: variables.particlesLightColor,
            distance: 100,
            enable: true,
            opacity: 0.7,
            width: 1,
        },
        collisions: {
            enable: true,
        },
        move: {
            direction: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: false,
            speed: 0.4,
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 250,
        },
        opacity: {
            value: 0.7,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 1, max: 2 },
        },
    },
    detectRetina: true,
}

export const optionsDark = {
    fullScreen: {
        enable: true,
        zIndex: -1
    },
    background: {
        color: {
            value: variables.particlesDarkBackground,
        },
    },
    fpsLimit: 60,
    interactivity: {
        events: {
          onHover: {
            enable: true,
            mode: "bubble",
          },
          resize: true,
        },
        modes: {
          bubble: {
            distance: 150,
            size: 4,
            opacity : 1,
            duration: 1,
          },
        },
      },
    particles: {
        color: {
            value: variables.particlesDarkColor,
        },
        links: {
            color: variables.particlesDarkColor,
            distance: 100,
            enable: true,
            opacity: 0.6,
            width: 1,
        },
        collisions: {
            enable: true,
        },
        move: {
            direction: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: false,
            speed: 0.4,
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 250,
        },
        opacity: {
            value: 0.3,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 1, max: 2 },
        },
    },
    detectRetina: true,
}